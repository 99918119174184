import {
  ServiceDescriptionRow,
  ServicePanelSplit,
  PanelRows,
  PanelInputRows,
} from "../Common/ServiceItem";
import { useSelector, useDispatch } from "react-redux";
import { conformToCurrency } from "../Common/Currency";
import {
  AddAvatarsAmount,
  AddAvatarsPerMonth,
} from "./PopularARAvatarsFeatureSpotSlice";
import img from "../../Images/PopularARAvatarFeatureSpot.png";
import { ImgWrapper } from "../../Images/index";
import { strings } from "../../../../strings";

export const ARAvatarsFeatureSpot = () => {
  const {
    ARAvatarsQuantity,
    NumberOfMonthPerAvatar,
    PerAvatarPerMonthCost,
    PerARAvatarProductionCost,
    total,
  } = useSelector((state) => state.PopularARAvatarsFeatureSpotReducer);
  const dispatch = useDispatch();
  const PanelInputArray = [
    {
      label: strings.service_popularArAvatarsFeature_label_numberOfArAvatars,
      value: ARAvatarsQuantity,
      onChange: (ev) => dispatch(AddAvatarsAmount(ev.target.value)),
      Max1Min0: true,
    },
    {
      label:
        strings.service_popularArAvatarsFeature_label_numberOfMonthPerAvatar,
      value: NumberOfMonthPerAvatar,
      onChange: (ev) => dispatch(AddAvatarsPerMonth(ev.target.value)),
    },
  ];
  const PanelArray = [
    {
      label:
        strings.service_popularArAvatarsFeature_label_perArAvatarProductionCost,
      value: conformToCurrency(PerARAvatarProductionCost),
    },
    {
      label:
        strings.service_popularArAvatarsFeature_label_perArAvatarFeatureSpot,
      value: conformToCurrency(PerAvatarPerMonthCost),
    },
    {
      label: strings.service_popularArAvatarsFeature_label_totalCost,
      value: conformToCurrency(total),
    },
  ];
  //   const dispatch = useDispatch();
  //   useEffect(() => {
  //     dispatch(AddModelAmount(50));
  //   }, []);
  return (
    <>
      <PanelInputRows PanelArray={PanelInputArray} />
      <PanelRows PanelArray={PanelArray} />
    </>
  );
};

export const PopularARAvatarsFeatureSpot = () => {
  return (
    <div>
      <ServiceDescriptionRow>
        {strings.service_popularArAvatarsFeature_description}
      </ServiceDescriptionRow>
      <ServicePanelSplit
        item1={<ImgWrapper src={img} />}
        item2={<ARAvatarsFeatureSpot />}
      />
    </div>
  );
};
