import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Home } from "./pages/pricingCalculator/v2/Page";
import { Layout } from "./components/Layout";
import { Reccomended } from "./pages/pricingCalculator/v2/Recommended";
import { configureStore } from "@reduxjs/toolkit";
import { Provider } from "react-redux";
import { Bespoke } from "./pages/pricingCalculator/v2/Bespoke";
import homePageReducer from "../src/pages/pricingCalculator/v2/ServiceCalculationComponents/HomePageBannerSlice";
import AR3DModelReducer from "../src/pages/pricingCalculator/v2/ServiceCalculationComponents/AR3DModelProductionSlice";
import ARShopInShopReducer from "./pages/pricingCalculator/v2/ServiceCalculationComponents/ARShopInShopSlice";
import PopularARAvatarsFeatureSpotReducer from "./pages/pricingCalculator/v2/ServiceCalculationComponents/PopularARAvatarsFeatureSpotSlice";
import PopularProductsFeatureSpotReducer from "./pages/pricingCalculator/v2/ServiceCalculationComponents/PopularProductsFeatureSpotSlice";
import ARAvatarReducer from "./pages/pricingCalculator/v2/ServiceCalculationComponents/ARAvatarSlice";
import OneToOneLiveVideoShoppingReducer from "./pages/pricingCalculator/v2/ServiceCalculationComponents/OneToOneLiveVideoShoppingSlice";
import VRExperienceReducer from "./pages/pricingCalculator/v2/ServiceCalculationComponents/VRExperiencesSlice";
import RecommendedReducer from "./pages/pricingCalculator/v2/RecommendedSlice";
export const store = configureStore({
  reducer: {
    homePageReducer,
    AR3DModelReducer,
    ARShopInShopReducer,
    PopularARAvatarsFeatureSpotReducer,
    PopularProductsFeatureSpotReducer,
    ARAvatarReducer,
    OneToOneLiveVideoShoppingReducer,
    VRExperienceReducer,
    RecommendedReducer,
  },
});

export default function App() {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route path="/" element={<Home />} />
            <Route path="/recommended" element={<Reccomended />} />
            <Route path="/bespoke" element={<Bespoke />} />
          </Route>
          <Route path="*" element={<NotFound />}></Route>
        </Routes>
      </BrowserRouter>
    </Provider>
  );
}

export const NotFound = (props) => {
  return (
    <>
      <div className="w-screen min-h-screen h-full flex flex-1 bg-gray-700 text-white items-center justify-center">
        <div className="w-96">
          <h1 className="text-5xl">not found</h1>
          <p className="text-xl mb-2">
            the requested resource does not exist at this time
          </p>
          <code className="bg-gray-900 rounded-lg text-sm text-white p-2">
            {window.location.pathname}
          </code>
        </div>
      </div>
    </>
  );
};
