import { createSlice } from "@reduxjs/toolkit";
import {
  formatNumber,
  updateWindowPayload,
} from "../../../../libs/ekyCloudMail";
import { strings } from "../../../../strings";
const initialState = {
  ARShopQuantity: 0,
  ARAvatarQuantity: 0,
  ARShopOneTimeCost: 4999,
  ARAvatarOneTimeCost: 2499,
  AnnualCost: 11988,
  total: 0,
};

function updatePayload(state) {
  updateWindowPayload("services", strings.service_arShopInShop_title, {
    [strings.service_arShopInShop_label_numberOfArShopInShop]:
      state.ARShopQuantity,
    [strings.service_arShopInShop_label_numberOfAvatars]:
      state.ARAvatarQuantity,
    [strings.service_arShopInShop_label_AnnualCost]: formatNumber(
      state.AnnualCost
    ),
    [strings.service_arShopInShop_label_TotalCost]: formatNumber(state.total),
  });
}

updatePayload(initialState);

const ARShopInShopSlice = createSlice({
  name: "ARShopInShop",
  initialState,
  reducers: {
    AddARShopInShop(state, amount) {
      state.ARShopQuantity = Math.min(amount.payload, 1);
      state.total =
        state.ARShopQuantity * state.ARShopOneTimeCost +
        state.ARAvatarQuantity * state.ARAvatarOneTimeCost +
        state.AnnualCost;

      //
      //  Update the window payload
      updatePayload(state);
    },
    AddARAvatar(state, amount) {
      state.ARAvatarQuantity = amount.payload;
      state.total =
        state.ARShopQuantity * state.ARShopOneTimeCost +
        state.ARAvatarQuantity * state.ARAvatarOneTimeCost +
        state.AnnualCost;

      //
      //  Update the window payload
      updatePayload(state);
    },
  },
});

export const { AddARShopInShop, AddARAvatar } = ARShopInShopSlice.actions;
export default ARShopInShopSlice.reducer;
