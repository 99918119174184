// ES6 module syntax
import LocalizedStrings from "react-localization";

let en = require("./en.json");
let fr = require("./fr.json");

export const strings = new LocalizedStrings({
  en,
  fr,
});
strings.setLanguage("fr");
