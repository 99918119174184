import {
  ServiceDescriptionRow,
  ServicePanelSplit,
  PanelRows,
  PanelInputRows,
} from "../Common/ServiceItem";
import { useSelector, useDispatch } from "react-redux";
import { conformToCurrency } from "../Common/Currency";
import { AddToAgentQuantity } from "./OneToOneLiveVideoShoppingSlice";

import img from "../../Images/OneToOneLiveVideoShopping-1.png";
import { ImgWrapper } from "../../Images/index";
import { strings } from "../../../../strings";

export const OneToOneLiveVideoShoppingPanel = () => {
  const { agentQuantity, setupCostPerAgent, annualCostPerAgent, total } =
    useSelector((state) => state.OneToOneLiveVideoShoppingReducer);

  const PanelArray = [
    {
      label: strings.service_oneToOneLiveVideo_label_setUpCostPerAgent,
      value: conformToCurrency(setupCostPerAgent),
    },
    {
      label: strings.service_oneToOneLiveVideo_label_annualCostPerAgent,
      value: conformToCurrency(annualCostPerAgent),
    },
    {
      label: strings.service_oneToOneLiveVideo_label_totalSetup,
      value: conformToCurrency(total),
    },
  ];
  const dispatch = useDispatch();

  return (
    <>
      <PanelInputRows
        PanelArray={[
          {
            label: strings.service_oneToOneLiveVideo_label_numberOfAgents,
            value: agentQuantity,
            onChange: (ev) => dispatch(AddToAgentQuantity(ev.target.value)),
          },
        ]}
      />
      <PanelRows PanelArray={PanelArray} />
    </>
  );
};

export const OneToOneLiveVideoShopping = () => {
  return (
    <div>
      <ServiceDescriptionRow>
        {strings.service_oneToOneLiveVideo_description}
      </ServiceDescriptionRow>
      <ServicePanelSplit
        item1={<ImgWrapper src={img} />}
        item2={<OneToOneLiveVideoShoppingPanel />}
      />
    </div>
  );
};
