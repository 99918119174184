import { createSlice } from "@reduxjs/toolkit";
import {
  formatNumber,
  updateWindowPayload,
} from "../../../../libs/ekyCloudMail";
import { strings } from "../../../../strings";

const initialState = {
  ARAvatarsQuantity: 0,
  NumberOfMonthPerAvatar: 0,
  PerAvatarPerMonthCost: 999,
  PerARAvatarProductionCost: 2499,
  total: 0,
};

const updatePayload = (state) =>
  updateWindowPayload(
    "services",
    strings.service_popularArAvatarsFeature_title,
    {
      [strings.service_popularArAvatarsFeature_label_numberOfArAvatars]:
        state.ARAvatarsQuantity,
      [strings.service_popularArAvatarsFeature_label_numberOfMonthPerAvatar]:
        state.NumberOfMonthPerAvatar,
      [strings.service_popularArAvatarsFeature_label_totalCost]: formatNumber(
        state.total
      ),
    }
  );

updatePayload(initialState);

const PopularARAvatarsFeatureSpotSlice = createSlice({
  name: "PopularARAvatarsFeatureSpot",
  initialState,
  reducers: {
    AddAvatarsAmount: (state, amount) => {
      state.ARAvatarsQuantity = Math.min(amount.payload, 1);
      state.total =
        state.ARAvatarsQuantity * state.PerARAvatarProductionCost +
        state.NumberOfMonthPerAvatar * state.PerAvatarPerMonthCost;

      //
      //  Update the window payload
      updatePayload(state);
    },
    AddAvatarsPerMonth: (state, amount) => {
      state.NumberOfMonthPerAvatar = amount.payload;
      state.total =
        state.ARAvatarsQuantity * state.PerARAvatarProductionCost +
        state.NumberOfMonthPerAvatar * state.PerAvatarPerMonthCost;

      //
      //  Update the window payload
      updatePayload(state);
    },
  },
});

export const { AddAvatarsAmount, AddAvatarsPerMonth } =
  PopularARAvatarsFeatureSpotSlice.actions;
export default PopularARAvatarsFeatureSpotSlice.reducer;
