import { createSlice } from "@reduxjs/toolkit";
import {
  formatNumber,
  updateWindowPayload,
} from "../../../../libs/ekyCloudMail";
import { limitNumberWithinRange } from "../Common/Currency";
import { strings } from "../../../../strings";

const initialState = {
  VRExperienceQuantity: 0,
  oneTimeDevelopmentCost: 19999,
  annualFee: 11988,
  total: 0,
};

const updatePayload = (state) =>
  updateWindowPayload("services", [strings.service_virtualExperience_title], {
    [strings.service_virtualExperience_label_totalCost]: formatNumber(
      state.total
    ),
    [strings.service_virtualExperience_label_numberOfVitualExperiences]:
      state.VRExperienceQuantity,
  });

updatePayload(initialState);

const VRExperienceSlice = createSlice({
  name: "VRExperience",
  initialState,
  reducers: {
    AddToVRExperienceAmount: (state, amount) => {
      state.VRExperienceQuantity = amount.payload || 0;
      state.total =
        state.VRExperienceQuantity * state.annualFee +
        limitNumberWithinRange(state.VRExperienceQuantity, 0, 1) *
          state.oneTimeDevelopmentCost;
      //
      //  Update the window payload
      updatePayload(state);
    },
  },
});

export const { AddToVRExperienceAmount } = VRExperienceSlice.actions;
export default VRExperienceSlice.reducer;
