import {
  ServiceDescriptionRow,
  ServicePanelSplit,
  PanelRows,
  PanelInputRows,
} from "../Common/ServiceItem";
import { useSelector, useDispatch } from "react-redux";
import { conformToCurrency } from "../Common/Currency";
import { AddAvatarQuantity } from "./ARAvatarSlice";
import img from "../../Images/ARAvatar-1.png";
import { ImgWrapper } from "../../Images/index";
import { strings } from "../../../../strings";

const ARShopInShopPanel = () => {
  const { ARAvatarCost, ARAvatarQuantity, syndicationCost, total } =
    useSelector((state) => state.ARAvatarReducer);
  const dispatch = useDispatch();

  const PanelInput = [
    {
      label: strings.service_arAvatar_label_numberOfArAvatars,
      value: ARAvatarQuantity,
      onChange: (ev) => {
        dispatch(AddAvatarQuantity(ev.target.value));
      },
    },
  ];
  const PanelArray = [
    {
      label: strings.service_arAvatar_label_costPerArAvatar,
      value: conformToCurrency(ARAvatarCost),
    },
    {
      label: strings.service_arAvatar_label_annualSyndicationCost,
      value: conformToCurrency(syndicationCost),
    },
    {
      label: strings.service_arAvatar_label_totalCost,
      value: conformToCurrency(total),
    },
  ];
  return (
    <>
      <PanelInputRows PanelArray={PanelInput} />
      <PanelRows PanelArray={PanelArray} />
    </>
  );
};

export const ARAvatar = () => {
  return (
    <div>
      <ServiceDescriptionRow>
        {strings.service_arAvatar_description}
      </ServiceDescriptionRow>
      <ServicePanelSplit
        item1={<ImgWrapper src={img} />}
        item2={<ARShopInShopPanel />}
      />
    </div>
  );
};
