import { createSlice } from "@reduxjs/toolkit";
import {
  formatNumber,
  updateWindowPayload,
} from "../../../../libs/ekyCloudMail";
import { strings } from "../../../../strings";

const initialState = {
  ProductsQuantity: 0,
  NumberOfMonthPerProduct: 1,
  PerProductPerMonth: 999,
  total: 0,
};

const updatePayload = (state) =>
  updateWindowPayload(
    "services",
    strings.service_popularProductsFeature_title,
    {
      //
      //  Update the window payload
      [strings.service_popularProductsFeature_label_NumberOfProductsSelected]:
        state.ProductsQuantity,
      [strings.service_popularProductsFeature_label_NumberOfMonthsPerProduct]:
        state.NumberOfMonthPerProduct,
      [strings.service_popularProductsFeature_label_totalCost]: formatNumber(
        state.total
      ),
    }
  );

updatePayload(initialState);

const PopularProductsFeatureSpotSlice = createSlice({
  name: "PopularProductsFeatureSpot",
  initialState,
  reducers: {
    AddProductAmount(state, amount) {
      state.ProductsQuantity = Math.min(amount.payload, 1);
      state.total =
        state.ProductsQuantity *
        state.NumberOfMonthPerProduct *
        state.PerProductPerMonth;
      updatePayload(state);
    },
  },
});

export const { AddProductAmount } = PopularProductsFeatureSpotSlice.actions;
export default PopularProductsFeatureSpotSlice.reducer;
