import React from "react";
import {
  ServiceDescriptionRow,
  ServicePanelSplit,
  PanelLabelValueRow,
  PanelLabelRow,
  Divider,
} from "../Common/ServiceItem";
import { useSelector, useDispatch } from "react-redux";
import { AddSelectedMonths } from "./HomePageBannerSlice";
import { conformToCurrency } from "../Common/Currency";

import { ImgWrapper } from "../../Images/index";
import img from "../../Images/HomePagebanner.png";

import { strings } from "../../../../strings";

const MonthItem = ({ children, onClick, selected }) => {
  return (
    <div
      onClick={onClick}
      style={{
        backgroundColor: "white",
        opacity: selected ? 1 : 0.5,
        color: "black",
        padding: 15,
        width: 60,
        borderRadius: 8,
        cursor: "pointer",
        userSelect: "none",
        display: "flex",
        justifyContent: "center",
      }}
    >
      {children}
    </div>
  );
};

const MonthSelectionRow = ({ children }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        marginBottom: 8,
      }}
    >
      {children}
    </div>
  );
};

const MonthSelectionButtons = () => {
  const { selectedMonths } = useSelector((state) => state.homePageReducer);

  const dispatch = useDispatch();

  const MonthArrayToRow = (months) => {
    return (
      <MonthSelectionRow>
        {months.map((month) => {
          const included = selectedMonths.includes(month);
          return (
            <MonthItem
              key={month}
              selected={included}
              onClick={() => {
                // const newSelected = included
                //   ? selectedMonths.filter((selected) => selected !== month)
                //   : [...selectedMonths, month];
                dispatch(AddSelectedMonths([month]));
              }}
            >
              {month}
            </MonthItem>
          );
        })}
      </MonthSelectionRow>
    );
  };
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        flexBasis: "50%",
      }}
    >
      {MonthArrayToRow(["Jan", "Feb", "Mar", "Apr"])}
      {MonthArrayToRow(["May", "Jun", "Jul", "Aug"])}
      {MonthArrayToRow(["Sep", "Oct", "Nov", "Dec"])}
    </div>
  );
};

const Calculator = ({ total, monthQuantity, monthlyFee }) => {
  //   const { Currency } = UseCurrency();

  return (
    <>
      <PanelLabelValueRow label={strings.service_homePageBanner_label_duration}>
        {monthQuantity}
      </PanelLabelValueRow>
      <Divider />
      <PanelLabelValueRow
        label={strings.service_homePageBanner_label_monthlyFee}
      >
        {conformToCurrency(monthlyFee)}
      </PanelLabelValueRow>
      <Divider />
      <PanelLabelRow
        label={strings.service_homePageBanner_label_monthsSelected}
      >
        <MonthSelectionButtons />
      </PanelLabelRow>
      <Divider />
      <PanelLabelRow label={strings.service_homePageBanner_label_totalCost}>
        <div
          style={{
            flexBasis: "50%",
            display: "flex",
            justifyContent: "center",
            color: "black",
          }}
        >
          {conformToCurrency(total)}
        </div>
      </PanelLabelRow>
    </>
  );
};

export const HomePageBanner = ({ Bespoke }) => {
  const state = useSelector((state) => state.homePageReducer);
  return (
    <div>
      <ServiceDescriptionRow>
        {strings.service_homePageBanner_description}
      </ServiceDescriptionRow>
      <ServicePanelSplit
        item1={<ImgWrapper src={img} />}
        item2={<Calculator {...state} />}
      />
    </div>
  );
};
