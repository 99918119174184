import { createSlice } from "@reduxjs/toolkit";
import {
  formatNumber,
  updateWindowPayload,
} from "../../../../libs/ekyCloudMail";

import { strings } from "../../../../strings";

const initialState = {
  total: 0,
  monthlyFee: 4999,
  monthQuantity: 0,
  selectedMonths: [],
};

const updatePayload = (state) =>
  updateWindowPayload("services", strings.service_homePageBanner_title, {
    [strings.service_homePageBanner_label_totalCost]: formatNumber(state.total),
    [strings.service_homePageBanner_label_duration]: state.monthQuantity,
    [strings.service_homePageBanner_label_monthlyFee]: formatNumber(
      state.monthlyFee
    ),
    [strings.service_homePageBanner_label_monthsSelected]: state.selectedMonths,
  });

updatePayload(initialState);

const counterSlice = createSlice({
  name: "homePageReducer",
  initialState,
  reducers: {
    AddSelectedMonths(state, amount) {
      state.selectedMonths = amount.payload;
      state.monthQuantity = amount.payload.length;
      state.total = state.monthQuantity * state.monthlyFee;

      //
      //  Update the window payload
      updatePayload(state);
    },
  },
});

export const { AddSelectedMonths } = counterSlice.actions;
export default counterSlice.reducer;
