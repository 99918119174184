//
//  :react & redux:
import React from "react";
import { Helmet } from "react-helmet";
import { Calculator } from "../svgs/Svgs";

//
//  :components:
import { Content } from "../../../components/Content";
import { useNavigate } from "react-router-dom";
import { strings } from "../../../strings";

const WelcomeContent = () => {
  return (
    <div className="w-full h-fit flex flex-col justify-center items-center bg-[white] md:p-9 p-4 text-black">
      <div className="md:w-4/5 w-[90%] h-fit flex flex-col items-center">
        <p className="mb-10 text-[37px] best-buy-font-med text-center ">
          {strings.home_welcomeTitle}
        </p>
        <p className="text-black best-buy-font-reg text-center max-w-[38rem] text-[15px]">
          {strings.home_welcomeCopy}
        </p>
      </div>
    </div>
  );
};

const ChoiceSection = ({ copy, route }) => {
  const navigate = useNavigate();
  return (
    <div
      onClick={() => {
        navigate(route);
        // console.log("choose");
      }}
      className=" bg-white rounded-lg basis-1/2 mr-5 flex md:flex-col justify-center text-black"
      style={{
        cursor: "pointer",
        borderRadius: 8,
        boxShadow:
          "rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px",
      }}
    >
      <div className="flex md:flex-row justify-between">
        <div
          style={{
            width: 300,
            paddingTop: 30,
            paddingBottom: 30,
            paddingLeft: 30,
            userSelect: "none",
          }}
        >
          {copy}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            width: 150,
            borderRadius: 8,
            boxShadow:
              "rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                width: 40,
              }}
            >
              <Calculator />
            </div>
          </div>

          <div style={{ marginTop: 10 }} className="text-center">
            Click Here
          </div>
        </div>
      </div>
    </div>
  );
};

const CalculationChoice = () => {
  return (
    <>
      <div className="md:w-4/5 w-[90%] flex items-center md:p-[6.25rem] p-4 ">
        <div id="header" className="w-full h-fit flex flex-col items-center">
          <div className="flex md:flex-row flex-col justify-evenly  w-full mt-4 best-buy-font-reg">
            <ChoiceSection
              route={"/recommended"}
              copy={strings.home_reccomended}
            />
            <ChoiceSection route={"/bespoke"} copy={strings.home_bespoke} />
          </div>
        </div>
      </div>
    </>
  );
};

export const Home = () => {
  return (
    <>
      <Helmet>
        <title>Eyekandy - Pricing Calculator</title>
        <meta property="og:title" content="Eyekandy - Pricing Calculator" />
        <meta name="description" content="" />
        <meta name="og:description" content="" />
      </Helmet>
      <Content classes="w-full flex flex-col items-center bg-[#10b6eb] best-buy-font-med">
        <WelcomeContent />
        <CalculationChoice />
      </Content>
    </>
  );
};
