import { animated, useSpring, to } from "@react-spring/web";
import React, { useState } from "react";

export const useInputEvents = () => {
  const [hover, setHover] = useState(false);
  const [pointerDown, setPointerDown] = useState(false);
  const [focus, setFocus] = useState(false);
  const reset = () => {
    setHover(false);
    setPointerDown(false);
    setFocus(false);
  };
  const events = {
    onPointerEnter: () => setHover(true),
    onPointerLeave: () => {
      setHover(false);
      setPointerDown(false);
    },
    onPointerDown: () => setPointerDown(true),
    onPointerUp: () => setPointerDown(false),
    onFocus: () => setFocus(true),
    onBlur: () => setFocus(false),
  };

  return { hover, pointerDown, focus, events, reset };
};

export const InputScaleWrapper = ({ children, style }) => {
  const { pointerDown, hover, events } = useInputEvents();
  const { pointerScale } = useSpring({ pointerScale: pointerDown ? 0.1 : 0 });
  const { hoverScale } = useSpring({ hoverScale: hover ? 1.05 : 1 });
  const transform = to(
    [hoverScale, pointerScale],
    (s, ps) => `scale(${s + ps})`
  );
  return (
    <animated.div style={{ transform }}>
      <div style={style} {...events}>
        {children}
      </div>
    </animated.div>
  );
};
