import { createSlice } from "@reduxjs/toolkit";
import { limitNumberWithinRange } from "../Common/Currency";
import {
  formatNumber,
  updateWindowPayload,
} from "../../../../libs/ekyCloudMail";

import { strings } from "../../../../strings";

export const initialState = {
  totalUnitCost: 24950,
  costPerAR3DModel: 499,
  numberOfAR3DModels: 0,
  syndicationCost: 5988,
  total: 0,
  averageCostPerProductPerMonth: 0,
};

const updatePayload = (state) => {
  updateWindowPayload("services", strings.service_3dArModelProduction_title, {
    [strings.service_3dArModelProduction_label_combinedTotalCost]: formatNumber(
      state.total
    ),
    [strings.service_3dArModelProduction_label_totalModelProductionCost]:
      formatNumber(state.totalUnitCost),
    [strings.service_3dArModelProduction_label_costPerModel]: formatNumber(
      state.costPerAR3DModel
    ),
    [strings.service_3dArModelProduction_label_numberOfModels]:
      state.numberOfAR3DModels,
    [strings.service_3dArModelProduction_label_annualSyndicationCost]:
      formatNumber(state.syndicationCost),
    [strings.service_3dArModelProduction_label_averageCostPerMonth]:
      formatNumber(state.averageCostPerProductPerMonth),
  });
};

updatePayload(initialState);

const AR3RModelSlice = createSlice({
  name: "AR3DModel",
  initialState,
  reducers: {
    AddModelAmount(state, amount) {
      state.numberOfAR3DModels = amount.payload;
      state.totalUnitCost = state.numberOfAR3DModels * state.costPerAR3DModel;
      state.total =
        state.totalUnitCost +
        limitNumberWithinRange(state.totalUnitCost, 0, 1) *
          state.syndicationCost;
      state.averageCostPerProductPerMonth = Math.ceil(
        state.total / 12 / state.numberOfAR3DModels
      );

      //
      //  Update the window payload
      updatePayload(state);
    },
  },
});

export const { AddModelAmount } = AR3RModelSlice.actions;
export default AR3RModelSlice.reducer;
