import { createSlice } from "@reduxjs/toolkit";
import {
  formatNumber,
  updateWindowPayload,
} from "../../../../libs/ekyCloudMail";

import { strings } from "../../../../strings";

const initialState = {
  ARAvatarCost: 2499,
  ARAvatarQuantity: 0,
  syndicationCost: 4788,
  total: 0,
};

const updatePayload = (state) =>
  updateWindowPayload("services", strings.service_arAvatar_title, {
    [strings.service_arAvatar_label_totalCost]: formatNumber(state.total),
    [strings.service_arAvatar_label_numberOfArAvatars]: state.ARAvatarQuantity,
    [strings.service_arAvatar_label_annualSyndicationCost]:
      state.syndicationCost,
  });

updatePayload(initialState);

const ARAvatarSlice = createSlice({
  name: "ARAvatar",
  initialState,
  reducers: {
    AddAvatarQuantity: (state, amount) => {
      state.ARAvatarQuantity = amount.payload;
      state.total =
        state.ARAvatarQuantity * state.ARAvatarCost +
        state.syndicationCost * state.ARAvatarQuantity;

      //
      //  Update the window payload
      updatePayload(state);
    },
  },
});

export const { AddAvatarQuantity } = ARAvatarSlice.actions;
export default ARAvatarSlice.reducer;
