import { createSlice } from "@reduxjs/toolkit";
import { updateWindowPayload } from "../../../libs/ekyCloudMail";
import { strings } from "../../../strings";

const initialState = {
  brand: "",
  contactName: "",
  email: "",
  phoneNumber: "",
};

const RecommendedSlice = createSlice({
  name: "RecommendedPage",
  initialState,
  reducers: {
    changeBrand(state, amount) {
      state.brand = amount.payload;

      //
      //  Update window payload
      updateWindowPayload("details", strings.reccomended_brand, state.brand);
    },
    changeContactName(state, amount) {
      state.contactName = amount.payload;

      //
      //  Update window payload
      updateWindowPayload(
        "details",
        strings.reccomended_contactName,
        state.contactName
      );
    },
    changeEmail(state, amount) {
      state.email = amount.payload;

      //
      //  Update window payload
      updateWindowPayload("details", strings.reccomended_email, state.email);
    },
    changeNumber(state, amount) {
      state.phoneNumber = amount.payload;

      //
      //  Update window payload
      updateWindowPayload(
        "details",
        strings.reccomended_phoneNumber,
        state.phoneNumber
      );
    },
  },
});

export const { changeBrand, changeContactName, changeEmail, changeNumber } =
  RecommendedSlice.actions;
export default RecommendedSlice.reducer;
