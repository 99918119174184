import { conformToCurrency } from "../pages/pricingCalculator/v2/Common/Currency";
import { strings } from "../strings";

const defaultEmails = [
  "vincent@eyekandy.com",
  "luc@eyekandy.com",
  "olivia@eyekandy.com",
];

export const updateWindowPayload = (type, service, object) => {
  //
  //  :step 1:
  //  Make sure we have the payload object in window
  if (!window.ekyCloudMailPayload) {
    window.ekyCloudMailPayload = {
      template: "costCalculator",
      destinations: defaultEmails,
      data: {
        subject: strings.mail_subject,
        introText: strings.mail_introText,
        content: {
          services: {
            [strings.service_homePageBanner_title]: { total: "not selected" },
            [strings.service_3dArModelProduction_title]: {
              total: "not selected",
            },
            [strings.service_arShopInShop_title]: { total: "not selected" },
            [strings.service_popularProductsFeature_title]: {
              total: "not selected",
            },
            [strings.service_popularArAvatarsFeature_title]: {
              total: "not selected",
            },
            [strings.service_arAvatar_title]: { total: "not selected" },
            [strings.service_oneToOneLiveVideo_title]: {
              total: "not selected",
            },
            [strings.service_virtualExperience_title]: {
              total: "not selected",
            },
            [strings.mail_totalText]: {},
          },
          details: {
            [strings.reccomended_brand]: "",
            [strings.reccomended_contactName]: "",
            [strings.reccomended_email]: "",
            [strings.reccomended_phoneNumber]: "",
          },
        },
      },
    };
  }

  // return;

  //
  //  :step 2:
  //  Update the object
  window.ekyCloudMailPayload.data.content[type][service] = object;
};

const getEkyCloudMailPayload = () => {
  return window.ekyCloudMailPayload || false;
};

export const sendEkyCloudMailPayload = async (email) => {
  //
  //  :step 1:
  //  Make sure we have a payload to send
  const payload = getEkyCloudMailPayload();
  if (!payload) {
    return false;
  }
  payload.destinations = [...defaultEmails, email];
  //
  //  :step 2:
  //  Post request to pass our payload off to the email package
  const response = await fetch(
    "https://0tbg9c0yw6.execute-api.eu-west-2.amazonaws.com/prod/send",
    {
      method: "post",
      body: JSON.stringify(payload),
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "5B46A0FABAD1600FE2A7086880CF7E99E658857DEC7A440C7F3E5E2453ADD38C",
      },
    }
  );

  // const data = await response.json()

  //
  //  :step 3:
  //  Return true/false depening on the status of the response
  return response.status === 200;
};

//
//  :helper:
export const formatNumber = (number) => {
  //
  //  Format our number with a currency
  return conformToCurrency(number);
};
