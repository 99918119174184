import {
  ServiceDescriptionRow,
  ServicePanelSplit,
  PanelRows,
  PanelInputRows,
} from "../Common/ServiceItem";
import { useSelector, useDispatch } from "react-redux";
import { conformToCurrency } from "../Common/Currency";
import { AddModelAmount } from "./AR3DModelProductionSlice";
import img from "../../Images/3DARModelProduction-1.png";
import { ImgWrapper } from "../../Images/index";
import { strings } from "../../../../strings";

const AR3DModelPanel = () => {
  const {
    totalUnitCost,
    costPerAR3DModel,
    numberOfAR3DModels,
    syndicationCost,
    total,
    averageCostPerProductPerMonth,
  } = useSelector((state) => state.AR3DModelReducer);
  const dispatch = useDispatch();

  const InputPanelArray = [
    {
      label: strings.service_3dArModelProduction_label_numberOfModels,
      value: numberOfAR3DModels,
      onChange: (ev) => {
        dispatch(AddModelAmount(ev.target.value));
      },
    },
  ];
  const PanelArray = [
    {
      label: strings.service_3dArModelProduction_label_costPerModel,
      value: conformToCurrency(costPerAR3DModel),
    },
    {
      label: strings.service_3dArModelProduction_label_totalModelProductionCost,
      value: conformToCurrency(totalUnitCost),
    },
    {
      label: strings.service_3dArModelProduction_label_annualSyndicationCost,
      value: conformToCurrency(syndicationCost),
    },
    {
      label: strings.service_3dArModelProduction_label_combinedTotalCost,
      value: conformToCurrency(total),
    },
    {
      label: strings.service_3dArModelProduction_label_averageCostPerMonth,
      value: conformToCurrency(averageCostPerProductPerMonth),
    },
  ];
  return (
    <>
      <PanelInputRows PanelArray={InputPanelArray} />
      <PanelRows PanelArray={PanelArray} />
    </>
  );
};

export const AR3DModelProduction = () => {
  return (
    <div>
      <ServiceDescriptionRow>
        {strings.service_3dArModelProduction_description}
      </ServiceDescriptionRow>
      <ServicePanelSplit
        item1={<ImgWrapper src={img} />}
        item2={<AR3DModelPanel />}
      />
    </div>
  );
};
