import { createSlice } from "@reduxjs/toolkit";
import {
  formatNumber,
  updateWindowPayload,
} from "../../../../libs/ekyCloudMail";
import { strings } from "../../../../strings";

const initialState = {
  agentQuantity: 0,
  setupCostPerAgent: 1499,
  annualCostPerAgent: 11988,
  total: 0,
};

const updatePayload = (state) =>
  updateWindowPayload("services", strings.service_oneToOneLiveVideo_title, {
    [strings.service_oneToOneLiveVideo_label_totalSetup]: formatNumber(
      state.total
    ),
    [strings.service_oneToOneLiveVideo_label_numberOfAgents]:
      state.agentQuantity,
  });
updatePayload(initialState);

const OneToOneLiveVideoShoppingSlice = createSlice({
  name: "OneToOneLiveVideoShopping",
  initialState,
  reducers: {
    AddToAgentQuantity: (state, amount) => {
      state.agentQuantity = amount.payload;
      state.total =
        state.agentQuantity * state.setupCostPerAgent +
        state.agentQuantity * state.annualCostPerAgent;

      //
      //  Update the window payload
      updatePayload(state);
    },
  },
});

export const { AddToAgentQuantity } = OneToOneLiveVideoShoppingSlice.actions;
export default OneToOneLiveVideoShoppingSlice.reducer;
