import { Content } from "../../../components/Content";
import { ServicesList } from "./Recommended";
import { Summary } from "./Recommended";
import { HomePageBanner } from "./ServiceCalculationComponents/HomePageBanner";
import { AR3DModelProduction } from "./ServiceCalculationComponents/AR3DModelProduction";
import { ARShopInShop } from "./ServiceCalculationComponents/ARShopInShop";
import { PopularProductsFeatureSpot } from "./ServiceCalculationComponents/PopularProductsFeatureSpot";
import { PopularARAvatarsFeatureSpot } from "./ServiceCalculationComponents/PopularARAvatarsFeatureSpot";
import { VRExperiences } from "./ServiceCalculationComponents/VRExperience";
import { OneToOneLiveVideoShopping } from "./ServiceCalculationComponents/OneToOneLiveVideoShopping";
import { ARAvatar } from "./ServiceCalculationComponents/ARAvatar";

import {
  ARAvatarIcon,
  ARModelProductionIcon,
  ARShopInShopIcon,
  HomePageBannerIcon,
  OneToOneLiveVideoShoppingIcon,
  PopularARAvatarIcon,
  PopularProductsFeatureSpotIcon,
  VirtualExperiencesIcon,
} from "../svgs/Svgs";

const Services = [
  {
    title: "Homepage Banner",
    component: HomePageBanner,
    icon: HomePageBannerIcon,
  },
  {
    title: "3D/ AR Model Production and Syndication",
    component: AR3DModelProduction,
    icon: ARModelProductionIcon,
    productPage: true,
  },
  {
    title: "AR Shop in Shop",
    component: ARShopInShop,
    icon: ARShopInShopIcon,
  },
  {
    title: "Popular Products Feature Spot",
    component: PopularProductsFeatureSpot,
    icon: PopularProductsFeatureSpotIcon,
  },
  {
    title: "Popular AR Avatars Feature Spot",
    component: PopularARAvatarsFeatureSpot,
    icon: PopularARAvatarIcon,
  },
  {
    title: "AR Avatar",
    component: ARAvatar,
    icon: ARAvatarIcon,
    productPage: true,
  },
  {
    title: "One to One Live Video Shopping",
    component: OneToOneLiveVideoShopping,
    icon: OneToOneLiveVideoShoppingIcon,
    productPage: true,
  },
  {
    title: "Virtual Experiences",
    component: VRExperiences,
    icon: VirtualExperiencesIcon,
    productPage: true,
  },
];

export const Bespoke = () => {
  return (
    <Content classes="w-full flex flex-col items-center bg-[#f7f7f7] best-buy-font-med">
      <p className="mb-10 mt-10 text-[37px] best-buy-font-med text-center text-black ">
        Cost Calculator
      </p>
      <ServicesList Services={Services} />
      <Summary />
      <div style={{ marginBottom: 100 }} />
    </Content>
  );
};
