import React from "react";
import {
  ServicePanelSplit,
  ServiceDescriptionRow,
  PanelRows,
  PanelInputRows,
} from "../Common/ServiceItem";
import { useSelector, useDispatch } from "react-redux";
import { conformToCurrency } from "../Common/Currency";
import { AddToVRExperienceAmount } from "./VRExperiencesSlice";
import img from "../../Images/VirtualExperiences.png";
import { ImgWrapper } from "../../Images/index";
import { strings } from "../../../../strings";

export const VRExperiencesPanel = () => {
  const { VRExperienceQuantity, oneTimeDevelopmentCost, annualFee, total } =
    useSelector((state) => state.VRExperienceReducer);
  const dispatch = useDispatch();
  const PanelArray = [
    {
      label: strings.service_virtualExperience_label_oneTimeDevelopmentCost,
      value: conformToCurrency(oneTimeDevelopmentCost),
    },
    {
      label: strings.service_virtualExperience_label_monthlyFee,
      value: conformToCurrency(annualFee),
    },
    {
      label: strings.service_virtualExperience_label_totalCost,
      value: conformToCurrency(total),
    },
  ];

  return (
    <>
      <PanelInputRows
        PanelArray={[
          {
            label:
              strings.service_virtualExperience_label_numberOfVitualExperiences,
            value: VRExperienceQuantity,
            onChange: (ev) =>
              dispatch(AddToVRExperienceAmount(ev.target.value)),
          },
        ]}
      />
      <PanelRows PanelArray={PanelArray} />
    </>
  );
};

export const VRExperiences = () => {
  return (
    <div>
      <ServiceDescriptionRow>
        {strings.service_virtualExperience_description}
      </ServiceDescriptionRow>
      <ServicePanelSplit
        item1={<ImgWrapper src={img} />}
        item2={<VRExperiencesPanel />}
      />
    </div>
  );
};
