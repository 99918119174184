//
//  :react & redux:
import React, { useEffect, useState } from "react";
// import { Helmet } from "react-helmet";

//
//  :components:
import { Content } from "../../../components/Content";
import { HomePageBanner } from "./ServiceCalculationComponents/HomePageBanner";
import { AR3DModelProduction } from "./ServiceCalculationComponents/AR3DModelProduction";
import { ARShopInShop } from "./ServiceCalculationComponents/ARShopInShop";
import { PopularProductsFeatureSpot } from "./ServiceCalculationComponents/PopularProductsFeatureSpot";
import { PopularARAvatarsFeatureSpot } from "./ServiceCalculationComponents/PopularARAvatarsFeatureSpot";
import { VRExperiences } from "./ServiceCalculationComponents/VRExperience";
import { OneToOneLiveVideoShopping } from "./ServiceCalculationComponents/OneToOneLiveVideoShopping";
import { ARAvatar } from "./ServiceCalculationComponents/ARAvatar";
import { ServiceItemWrapper } from "./Common/ServiceItem";

import { ServiceItem, TwoColumnSplit } from "./Common/ServiceItem";
import { conformToCurrency } from "./Common/Currency";
import { useDispatch, useSelector } from "react-redux";
import {
  changeBrand,
  changeContactName,
  changeEmail,
  changeNumber,
} from "../v2/RecommendedSlice";
import { InputScaleWrapper } from "./Common/input";

//
//  :eky-cloud-mail:
import {
  sendEkyCloudMailPayload,
  updateWindowPayload,
  formatNumber,
} from "../../../libs/ekyCloudMail";
import {
  ARAvatarIcon,
  ARModelProductionIcon,
  ARShopInShopIcon,
  HomePageBannerIcon,
  OneToOneLiveVideoShoppingIcon,
  PopularARAvatarIcon,
  PopularProductsFeatureSpotIcon,
  VirtualExperiencesIcon,
} from "../svgs/Svgs";
import { strings } from "../../../strings";

async function submit(email) {
  if (!email) {
    if (email.length === 0) {
      alert("empty email");
      return;
    }

    alert("invalid email");
    return;
  }
  return await sendEkyCloudMailPayload(email);
}

const FullWidthSection = ({ children }) => (
  <div className="w-full h-fit flex flex-col justify-center  md:p-9 p-4 text-black">
    {children}
  </div>
);
const Services = [
  {
    title: strings.service_homePageBanner_title,
    component: HomePageBanner,
    icon: HomePageBannerIcon,
  },
  {
    title: strings.service_3dArModelProduction_title,
    component: AR3DModelProduction,
    icon: ARModelProductionIcon,
    productPage: true,
  },
  {
    title: strings.service_arShopInShop_title,
    component: ARShopInShop,
    icon: ARShopInShopIcon,
  },
  {
    title: strings.service_popularProductsFeature_title,
    component: PopularProductsFeatureSpot,
    icon: PopularProductsFeatureSpotIcon,
  },
  {
    title: strings.service_popularArAvatarsFeature_title,
    component: PopularARAvatarsFeatureSpot,
    icon: PopularARAvatarIcon,
  },
  {
    title: strings.service_arAvatar_title,
    component: ARAvatar,
    icon: ARAvatarIcon,
    productPage: true,
  },
  {
    title: strings.service_oneToOneLiveVideo_title,
    component: OneToOneLiveVideoShopping,
    icon: OneToOneLiveVideoShoppingIcon,
    productPage: true,
  },
  {
    title: strings.service_virtualExperience_title,
    component: VRExperiences,
    icon: VirtualExperiencesIcon,
    productPage: true,
  },
];
export const ServicesList = ({ Services }) => {
  return (
    <FullWidthSection>
      {Services.map(({ title, component, icon, productPage }) => {
        return (
          <ServiceItem
            key={title}
            copy={title}
            ServiceComponent={component}
            Icon={icon}
            productPage={productPage}
          />
        );
      })}
    </FullWidthSection>
  );
};

const SummaryItem = ({ copy, buttonText, onClick, disabled = false }) => {
  return (
    <ServiceItemWrapper
      className={
        disabled
          ? "bg-slate-700 rounded-lg flex text-white"
          : "bg-slate-500 rounded-lg flex text-white"
      }
      style={{ height: 300 }}
    >
      <div className="flex justify-between" style={{ flexGrow: 1 }}>
        <div
          style={{
            paddingTop: 30,
            paddingBottom: 30,
            paddingLeft: 30,
            paddingRight: 30,
            userSelect: "none",
            width: 300,
          }}
        >
          {copy}
        </div>
      </div>
      <div
        onClick={disabled ? () => {} : onClick}
        style={{
          backgroundColor: disabled ? "grey" : "white",
          color: disabled ? "white" : "#10b6eb",
          flexBasis: 300,
          display: "flex",
          justifyContent: "center",
          borderRadius: 8,
          cursor: "pointer",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          {buttonText}
        </div>
      </div>
    </ServiceItemWrapper>
  );
};

const SummaryPanel = () => {
  const { brand, contactName, email, phoneNumber } = useSelector(
    (state) => state.RecommendedReducer
  );

  useEffect(() => {
    updateWindowPayload("details", strings.reccomended_brand, brand);
    updateWindowPayload(
      "details",
      strings.reccomended_contactName,
      contactName
    );
    updateWindowPayload("details", strings.reccomended_email, email);
    updateWindowPayload(
      "details",
      strings.reccomended_phoneNumber,
      phoneNumber
    );
  }, [brand, contactName, email, phoneNumber]);
  const dispatch = useDispatch();
  return (
    <div
      style={{
        padding: 30,
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#10b6eb",
        borderRadius: 8,
        width: "100%",
        height: "100%",
        color: "white",
      }}
    >
      {[
        {
          label: strings.reccomended_brand,
          value: brand,
          onChange: (ev) => dispatch(changeBrand(ev.target.value)),
        },
        {
          label: strings.reccomended_contactName,
          value: contactName,
          onChange: (ev) => dispatch(changeContactName(ev.target.value)),
        },
        {
          label: strings.reccomended_email,
          value: email,
          mask: true,
          onChange: (ev) => dispatch(changeEmail(ev.target.value)),
        },
        {
          label: strings.reccomended_phoneNumber,
          value: phoneNumber,
          onChange: (ev) => dispatch(changeNumber(ev.target.value)),
        },
      ].map(({ label, value, mask, onChange }) => (
        <div key={label} style={{ display: "flex" }}>
          <div style={{ flexBasis: "30%", paddingBottom: 10 }}>{label}</div>
          <div style={{ flexBasis: "50%", color: "white" }}>
            <InputScaleWrapper>
              <input
                style={{
                  backgroundColor: "transparent",
                  color: "white",
                  borderWidth: 2,
                  borderRadius: 8,
                  textAlign: "center",
                }}
                placeholder={mask ? "name@host.com" : "'"}
                value={value}
                onChange={onChange}
              />
            </InputScaleWrapper>
          </div>
        </div>
      ))}
      <div style={{ paddingTop: 10 }}>{strings.recommended_summary}</div>
    </div>
  );
};

const SummaryItems = ({ total }) => {
  const [sending, setsending] = useState(false);
  const { email } = useSelector((state) => state.RecommendedReducer);
  return (
    <div>
      {[
        {
          copy: strings.reccomended_totalCost,
          button: total,
        },
        // { copy: "Generate quote in PDF", button: "Click here" },
        // {
        //   copy: "Send quote for signature via docusign",
        //   button: "Click here",
        // },
      ].map(({ copy, button }, i) => (
        <div key={copy} style={{ marginTop: i > 0 ? 8 : 0 }}>
          <SummaryItem copy={copy} buttonText={button} />
        </div>
      ))}
      <div style={{ marginTop: 8 }} />
      <SummaryItem
        copy={strings.reccomended_clickToSendEmail}
        buttonText={
          sending ? strings.recommended_sending : strings.recommended_send
        }
        disabled={sending}
        onClick={async () => {
          setsending(true);
          await submit(email || "");

          setsending(false);
        }}
      />
    </div>
  );
};

export const Summary = () => {
  const total = useSelector(
    (state) =>
      state.AR3DModelReducer.total +
      state.homePageReducer.total +
      state.ARShopInShopReducer.total +
      state.PopularARAvatarsFeatureSpotReducer.total +
      state.PopularProductsFeatureSpotReducer.total +
      state.ARAvatarReducer.total +
      state.OneToOneLiveVideoShoppingReducer.total +
      state.VRExperienceReducer.total
  );

  useEffect(() => {
    updateWindowPayload("services", strings.mail_totalText, {
      total: formatNumber(total),
    });
  }, [total]);

  return (
    <FullWidthSection>
      <div style={{ marginBottom: 8 }} />
      <TwoColumnSplit
        item1={
          <>
            <SummaryItems total={conformToCurrency(total)} />
          </>
        }
        item2={<SummaryPanel />}
      />
    </FullWidthSection>
  );
};

export const Reccomended = () => {
  return (
    <Content classes="w-full flex flex-col items-center bg-[#f7f7f7] best-buy-font-med">
      <p className="mb-10 mt-10 text-[37px] best-buy-font-med text-center text-black ">
        {strings.recommended_costCalculator}
      </p>
      <ServicesList Services={Services} />
      <Summary />
      <div style={{ marginBottom: 100 }} />
    </Content>
  );
};
