import {
  ServiceDescriptionRow,
  ServicePanelSplit,
  PanelRows,
  PanelInputRows,
} from "../Common/ServiceItem";
import { useSelector, useDispatch } from "react-redux";
import { conformToCurrency } from "../Common/Currency";
import { AddARShopInShop, AddARAvatar } from "./ARShopInShopSlice";

import img from "../../Images/ARShopInShop-1.png";
import { ImgWrapper } from "../../Images/index";
import { strings } from "../../../../strings";

const ARShopInShopPanel = () => {
  const {
    ARShopQuantity,
    ARAvatarQuantity,
    ARShopOneTimeCost,
    ARAvatarOneTimeCost,
    AnnualCost,
    total,
  } = useSelector((state) => state.ARShopInShopReducer);
  const dispatch = useDispatch();

  const PaneInputArray = [
    {
      label: strings.service_arShopInShop_label_numberOfArShopInShop,
      value: ARShopQuantity,
      onChange: (ev) => dispatch(AddARShopInShop(ev.target.value)),
    },
    {
      label: strings.service_arShopInShop_label_numberOfAvatars,
      value: ARAvatarQuantity,
      onChange: (ev) => dispatch(AddARAvatar(ev.target.value)),
    },
  ];
  const PanelArray = [
    {
      label: strings.service_arShopInShop_label_ARShopOneTimeCost,
      value: conformToCurrency(ARShopOneTimeCost),
    },
    {
      label: strings.service_arShopInShop_labelARAvatarOneTimeCost,
      value: conformToCurrency(ARAvatarOneTimeCost),
    },
    {
      label: strings.service_arShopInShop_label_AnnualCost,
      value: conformToCurrency(AnnualCost),
    },
    {
      label: strings.service_arShopInShop_label_TotalCost,
      value: conformToCurrency(total),
    },
  ];
  return (
    <>
      <PanelInputRows PanelArray={PaneInputArray} />
      <PanelRows PanelArray={PanelArray} />
    </>
  );
};

export const ARShopInShop = () => {
  return (
    <div>
      <ServiceDescriptionRow>
        {strings.service_arShopInShop_description}
      </ServiceDescriptionRow>
      <ServicePanelSplit
        item1={<ImgWrapper src={img} />}
        item2={<ARShopInShopPanel />}
      />
    </div>
  );
};
