import React from "react";
import {
  ServicePanelSplit,
  ServiceDescriptionRow,
  PanelRows,
  PanelInputRows,
} from "../Common/ServiceItem";
import { useSelector, useDispatch } from "react-redux";
import { conformToCurrency } from "../Common/Currency";
import { AddProductAmount } from "./PopularProductsFeatureSpotSlice";
import img from "../../Images/PopularProductsFeatureSpot.png";
import { ImgWrapper } from "../../Images/index";
import { strings } from "../../../../strings";

export const PopularProductsPanel = () => {
  const {
    ProductsQuantity,
    NumberOfMonthPerProduct,
    PerProductPerMonth,
    total,
  } = useSelector((state) => state.PopularProductsFeatureSpotReducer);
  const dispatch = useDispatch();
  const PanelInputArray = [
    {
      label:
        strings.service_popularProductsFeature_label_NumberOfProductsSelected,
      value: ProductsQuantity,
      Max1Min0: true,
      onChange: (ev) => dispatch(AddProductAmount(ev.target.value)),
    },
  ];

  const PanelArray = [
    {
      label:
        strings.service_popularProductsFeature_label_NumberOfMonthsPerProduct,
      value: NumberOfMonthPerProduct,
    },
    {
      label: strings.service_popularProductsFeature_label_costPerMonth,
      value: conformToCurrency(PerProductPerMonth),
    },
    {
      label: strings.service_popularProductsFeature_label_totalCost,
      value: conformToCurrency(total),
    },
  ];

  return (
    <>
      <PanelInputRows PanelArray={PanelInputArray} />
      <PanelRows PanelArray={PanelArray} />
    </>
  );
};

export const PopularProductsFeatureSpot = () => {
  return (
    <div>
      <ServiceDescriptionRow>
        {strings.service_popularProductsFeature_description}
      </ServiceDescriptionRow>
      <ServicePanelSplit
        item1={<ImgWrapper src={img} />}
        item2={<PopularProductsPanel />}
      />
    </div>
  );
};
